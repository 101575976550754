import React from 'react';

import ShareEntity from 'components/Globals/ShareEntity';
import FAQ from 'components/Globals/FAQ';
import EntityEditButton, { useEntityEditPermissions } from 'components/Globals/EntityEditButton';

import SpriteIcon from 'components/uiLibrary/SpriteIcon';
import CastingToolPromo from 'components/Globals/CastingToolPromo';

import { COMPONENTS } from 'components/Globals/Analytics/constants';
import useDeviceTypeLayouts from 'utils/hooks/useDeviceTypeLayouts';

import { ENTITY_TYPES } from 'operabase-router/constants';
import classes from './Actions.module.scss';

const Actions = ({ entityType, entity, trackingData }) => {
  const { isMobile: _isMobile, isDesktop: _isDesktop } = useDeviceTypeLayouts();
  const { showEditBtn } = useEntityEditPermissions();

  return (
    <div className={classes.actions}>
      <div className={classes.actions_left}>
        {showEditBtn && entity?.id && (
          <div className={classes.actions_left__editBtn}>
            <EntityEditButton
              className={classes.editAction}
              size="medium"
              shape="rounded"
              trackingData={trackingData}
            />
          </div>
        )}
        <div className={classes.bookmark}>
          <SpriteIcon icon="bookmark_add" size={16} />
        </div>
        <ShareEntity
          entityType={entityType}
          entity={entity}
          className={classes.shareIconButton}
          trackingData={{
            component: COMPONENTS.SHARE_CTA,
          }}
          renderIconButton
        />
        <FAQ
          trackingData={{
            ...trackingData,
            component: COMPONENTS.FAQ_CTA,
          }}
        />
      </div>
      <div className={classes.rightSide}>
        <>
          {entityType === ENTITY_TYPES.PROFILE && entity?.id && (
            <div className={classes.rightSide__castingToolPromo}>
              <CastingToolPromo
                entityType={entityType}
                entity={entity}
                asCTA
                trackingData={{
                  ...trackingData,
                  component: COMPONENTS.VIEW_IN_CASTING_TOOL_CTA,
                }}
              />
            </div>
          )}
        </>
      </div>
    </div>
  );
};

export default Actions;
