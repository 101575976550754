import React from 'react';
import classnames from 'classnames';
import dynamic from 'next/dynamic';

import mediaQueries from 'containers/Media/queries';
import { useQuery } from 'utils/react-query';
import usePageContext from 'utils/hooks/usePageContext';
import useAppContext from 'utils/hooks/useAppContext';
import { ENTITY_TYPES } from 'operabase-router/constants';

import classes from './PageCommonContent.module.scss';

const MediaWidget = dynamic(() => import('components/Media/Display/MediaWidget'));
const ArtistPremiumSections = dynamic(() => import('components/Artist/Display/ArtistPremiumSections'));

const RenderMediaWidget = ({ entity, entityType }) => {
  const { obRouteContext } = useAppContext();
  const { linkProps } = obRouteContext;
  const entityId = entity?.id;

  const shouldRenderMediaWidget =
    !linkProps?.tab &&
    !!entityId &&
    [ENTITY_TYPES.PROFILE, ENTITY_TYPES.ORGANIZATION].includes(entityType) &&
    entity?.stats?.media?.widget?.exists;

  const { data: hasMediaWidget = false } = useQuery(
    mediaQueries.getMediaSuggestions({
      entityType,
      entityId,
      queryConfig: {
        enabled: shouldRenderMediaWidget,
        select: response => response?.data?.length > 0,
      },
    }),
  );

  if (!shouldRenderMediaWidget || !hasMediaWidget) {
    return null;
  }

  return (
    <div className={classes.section}>
      <div className={classnames(classes.section__container, classes.noEdgePadding)}>
        <MediaWidget entityType={entityType} entity={entity} />
      </div>
    </div>
  );
};

const PremiumSection = ({ entity, entityType }) => {
  const { obRouteContext } = useAppContext();
  const { linkProps } = obRouteContext;
  const entityId = entity?.id;
  const isPaidEntity = entity?.stats?.isPro;
  if (!entityId || entityType !== ENTITY_TYPES.PROFILE || !isPaidEntity || linkProps?.tab) {
    return null;
  }

  return (
    <div className={classes.section}>
      <div className={classnames(classes.section__container, classes.noEdgePadding)}>
        <ArtistPremiumSections />
      </div>
    </div>
  );
};

const PageCommonContent = () => {
  const { obRouteContext } = useAppContext();
  const { entity } = usePageContext();
  const { page } = obRouteContext;
  const { entityType } = page;

  return (
    <div className={classes.root}>
      <RenderMediaWidget entity={entity} entityType={entityType} />
      <PremiumSection entity={entity} entityType={entityType} />
    </div>
  );
};

export default PageCommonContent;
