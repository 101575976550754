import React, { useMemo } from 'react';
import dynamic from 'next/dynamic';
import classnames from 'classnames';

import PageWrapper from 'components/Globals/Layout/PageWrapper';
import { ThemeProvider } from 'components/Globals/Layout/ThemeProvider';
import Breadcrumbs from 'components/uiLibrary/Breadcrumbs';
import ContextualFilter from 'components/Globals/Layout/ContextualFilter';
import StickyHeader from 'components/Globals/Layout/Header/StickyHeader';

import { SECTIONS } from 'components/Globals/Analytics/constants';
import FilterFacets, { FILTER_LAYOUT } from 'components/Filters/Display/FilterFacets';
import AppliedFilters from 'components/Filters/Display/AppliedFilters';

import useAppContext from 'utils/hooks/useAppContext';
import { ENTITY_TYPES, FILTER_TYPES } from 'operabase-router/constants';
import usePageContext from 'utils/hooks/usePageContext';
import useDeviceTypeLayouts from 'utils/hooks/useDeviceTypeLayouts';
import useEntitySectionTabs from 'utils/hooks/useEntitySectionTabs';
import useOnScrollBeyondElement from 'utils/hooks/useOnScrollBeyondElement';

import { ENTITY_MAIN_TABS } from 'constants/index';

import PageH1 from './PageH1';
import PageCommonContent from './PageCommonContent';
import { THEMES } from '../StubPage/Content';
import Actions from './Actions';

import classes from './PageLayout.module.scss';

/* NOTE: Using shared component from Globals */
const ContextualFootLinks = dynamic(() => import('components/Globals/ContextualFootLinks'));
const ArtistIndexPageFilters = dynamic(() => import('components/Artist/Display/IndexPageFilters'));
const AgenciesIndexPageFilters = dynamic(() => import('components/Manager/Display/IndexPageFilters'));
const PageNavigationTabs = dynamic(() => import('components/uiLibrary/PageNavigationTabs'));
const OrganizationIndexPageFilters = dynamic(() => import('components/Organization/Display/IndexPageFilters'), {
  ssr: false,
});

const INDEX_PAGE_FILTERS = {
  [ENTITY_TYPES.PROFILE]: ArtistIndexPageFilters,
  [ENTITY_TYPES.ORGANIZATION]: OrganizationIndexPageFilters,
  [ENTITY_TYPES.AGENCY]: AgenciesIndexPageFilters,
};

const PageActions = ({ entityType, entity }) => (
  <div className={classes.action}>
    <Actions
      entityType={entityType}
      entity={entity}
      trackingData={{
        section: SECTIONS.ENTITY_HEADER,
      }}
    />
  </div>
);

const H1Header = () => {
  const { isDesktop } = useDeviceTypeLayouts();
  const { obRouteContext } = useAppContext();
  const { entity } = usePageContext();
  const { page } = obRouteContext;
  const { entityType } = page;

  return (
    <div className={classes.h1Header}>
      <PageH1 maxFontSize={26} minFontSize={26} styles={{ title: classes.h1Header_title }} />
      {isDesktop && <PageActions entity={entity} entityType={entityType} />}
    </div>
  );
};

const PageHeader = ({ entityType, entity }) => {
  const IndexPageFilters = INDEX_PAGE_FILTERS[entityType];
  const shouldShowFilters = !entity?.id && entityType !== ENTITY_TYPES.AGENCY;
  const { ref, isScrolledBeyond } = useOnScrollBeyondElement();

  return (
    <>
      <div className={classes.header} ref={ref}>
        <H1Header />
        {!entity?.id && IndexPageFilters && (
          <div
            className={classnames({
              [classes.indexPageFilters]: !!shouldShowFilters,
            })}
          >
            <IndexPageFilters />
          </div>
        )}
        {!entityType && (
          <div className={classes.contextualFilter}>
            <ContextualFilter />
          </div>
        )}
        {shouldShowFilters && <Filters entity={entity} entityType={entityType} />}
      </div>
      <StickyHeader isScrolledBeyond={isScrolledBeyond} />
    </>
  );
};

const Filters = ({ entity, entityType }) => {
  const { obRouteContext } = useAppContext();
  const { linkProps } = obRouteContext;
  const shouldShowFilters = !entity?.id || (!!entity?.id && linkProps?.tab === ENTITY_MAIN_TABS.PERFORMANCES);

  const excludeFilterTypes = useMemo(() => {
    if (!entity?.id) {
      return [FILTER_TYPES.WATCH_ONLINE];
    }
    return [];
  }, [entity?.id]);

  if (!shouldShowFilters) {
    return null;
  }

  return (
    <div
      className={classnames(classes.filtersFacetsWrapper, {
        [classes.filtersFacetsWrapper_filtersOnEntityPage]: !!entity?.id,
        [classes.filtersFacetsWrapper_withoutContextualFilter]: !!entityType,
      })}
    >
      <FilterFacets
        layout={FILTER_LAYOUT.HORIZONTAL}
        styles={{
          root: classes.filterContainerDetailed,
          filterGroupBox: classes.filterGroupBox,
        }}
        ignoreSmallScreenGroup
        truncateInOneLine
        scrollable
      />
      <div className={classes.appliedFiltersWrapper}>
        <AppliedFilters clearExcludedTypes={excludeFilterTypes} />
      </div>
    </div>
  );
};

const PageFooter = () => (
  <div className={classes.footer}>
    <ContextualFootLinks />
    <Breadcrumbs />
  </div>
);

const NavigationTabs = ({ entityType, entity }) => {
  const { obRouteContext } = useAppContext();
  const { isDesktop } = useDeviceTypeLayouts();
  const linkProps = obRouteContext?.linkProps;
  const isStubPageWithTabs = !!entity?.id && entityType !== ENTITY_TYPES.PRODUCTION;
  const tabs = useEntitySectionTabs({ entityType, entity, mainPath: linkProps?.tab, filters: linkProps?.filters });
  const activeTab = tabs?.find(({ key }) => key === linkProps?.tab) || tabs?.[0];

  if (!isDesktop || !isStubPageWithTabs) {
    return null;
  }

  return (
    <div className={classes.navigation}>
      <PageNavigationTabs tabs={tabs} activeTab={activeTab} />
    </div>
  );
};

/**
 * PageLayout component for standard page layouts
 */
const PageLayout = ({ children }) => {
  const { obRouteContext } = useAppContext();
  const { entity } = usePageContext();
  const { page } = obRouteContext;
  const { entityType } = page;
  const isPaidEntity = entity?.stats?.isPro;

  const premiumThemeContext = useMemo(
    () => (ENTITY_TYPES.PROFILE === entityType && isPaidEntity ? THEMES.DARK : THEMES.LIGHT),
    [entityType, isPaidEntity],
  );

  return (
    <PageWrapper>
      <ThemeProvider theme={premiumThemeContext}>
        <div className={classes.root}>
          <PageHeader entity={entity} entityType={entityType} />
          <NavigationTabs entity={entity} entityType={entityType} />
          {entity?.id && (
            <div className={classnames(classes.contextualFilter, { [classes.contextualFilter_entity]: entity?.id })}>
              <ContextualFilter />
            </div>
          )}
          {entity?.id && <Filters entity={entity} entityType={entityType} />}
          <PageCommonContent />
        </div>
        <ThemeProvider theme={THEMES.LIGHT}>
          <div className={classes.children}>{children}</div>
        </ThemeProvider>
        <PageFooter />
      </ThemeProvider>
    </PageWrapper>
  );
};

export default PageLayout;
