/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useMemo, Fragment } from 'react';
import classnames from 'classnames';

import HorizontalScroller from 'components/uiLibrary/HorizontalScroller';
import TextFit from 'components/uiLibrary/TextFit';
import EntityName from 'components/Globals/EntityName';

import useAppContext from 'utils/hooks/useAppContext';

import { OPERATOR_LABELS } from 'operabase-router/constants';

import { useTranslation } from 'src/i18n';

import classes from './PageH1.module.scss';

const PageH1 = ({ skipFilters = false, maxFontSize = 26, minFontSize = 14 }) => {
  const { obRouteContext } = useAppContext();
  const { t } = useTranslation('NS_APP_GLOBALS');

  const { h1Title } = obRouteContext;

  const { titleParts } = useMemo(() => {
    if (skipFilters) {
      return {
        titleParts: h1Title?.[0]?.length > 0 ? [h1Title?.[0]] : [],
      };
    }

    return {
      titleParts: h1Title,
    };
  }, [h1Title, skipFilters]);

  return (
    <div className={classes.root}>
      <HorizontalScroller styles={{ scrollable: classes.scrollablePageH1 }}>
        <TextFit
          text={titleParts}
          max={maxFontSize}
          min={minFontSize}
          textRenderer={text => (
            <h1 className={classes.title}>
              {text?.map((part, index) => (
                <Fragment key={index}>
                  {index > 0 && ' / '}
                  {part?.map((subPart, subIndex) => (
                    <Fragment key={subIndex}>
                      {subPart?.operator && (
                        <span className={classes.title_operator}> {t(OPERATOR_LABELS[subPart.operator])} </span>
                      )}
                      <span
                        className={classnames(classes.title_part, {
                          [classes.title_part__with_click]: !!subPart.onClick,
                        })}
                        data-headline={subPart.headline?.map(h => t(h)).join(' | ')}
                        onClick={subPart.onClick}
                      >
                        {t(subPart.name)}
                        {subPart.composers?.length > 0 && (
                          <>
                            {', '}
                            {subPart.composers?.map(composer => (
                              <EntityName
                                name={composer.name}
                                entityType={composer.identifier.entityType}
                                entity={{ id: composer.identifier.entityId, name: composer.name }}
                              />
                            ))}
                          </>
                        )}
                        {subPart.attributes?.length > 0 && (
                          <span className={classes.title_subpart}>
                            <span className={classes.title_subpart__enclosure}>{' ('}</span>
                            {subPart.attributes?.map(h => t(h)).join(' | ')}
                            <span className={classes.title_subpart__enclosure}>)</span>
                          </span>
                        )}
                      </span>
                    </Fragment>
                  ))}
                </Fragment>
              ))}
            </h1>
          )}
        />
      </HorizontalScroller>
    </div>
  );
};

export default PageH1;
